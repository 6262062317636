
            @import 'src/styles/mixins.scss';
        
.bg {
    background-image: url(/assets/images/qoutBg.svg);
    background-repeat: no-repeat;
    background-position: left 320px;
    min-height: 100vh;
    padding-bottom: 50px;
    @media screen and (max-width: 768px) {
        background-size: 150%;
        background-position: -100px 550px;
    }
}
